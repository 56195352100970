import React from "react"
import { graphql } from "gatsby"
import { HowItWorks as PageQuery, Link, Meta, HowItWorksSEO, Menu, SiteSettings, SiteBanner } from 'src/queries'
import Layout from 'src/components/layout'
import Slices from 'src/components/slices'
import Instagram from 'src/components/instagram'
import { getPageData } from 'src/utilities/data'
import Steps from 'src/components/steps'
import SEO from 'src/components/seo'

const HowItWorks = getPageData(({ pageData, menuData, siteBannerData, siteSettings, pageContext }) => (
  <Layout pageContext={pageContext} menuData={menuData} siteBannerData={siteBannerData} siteSettings={siteSettings}>
    <SEO {...pageData} lang={pageContext.lang} />
    <Steps data={pageData} />
    <Slices data={pageData.body} />
    <Instagram menuData={menuData} />
  </Layout>
))

HowItWorks.fragments = [PageQuery, Link, Meta, HowItWorksSEO, Menu, SiteSettings, SiteBanner]
export default HowItWorks
export const query = graphql`
  query ($lang: String) {
    prismic {
      allHow_it_works_15s(lang: $lang) { ...HowItWorks }
      allMenus(lang: $lang) { ...Menu }
      allSite_settingss(lang: $lang) { ...SiteSettings }
      allSite_banners(lang: $lang) { ...SiteBanner }
    }
  }
`