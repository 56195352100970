import React, { useState } from 'react'
import styles from 'src/css/steps.module.css'
import Image from 'src/components/image'
import LinkButton from 'src/components/link_button'
import ArrowRightNarrow from 'src/images/arrow_right_narrow'
import ArrowLeft from 'src/images/arrow_left'
import ArrowRight from 'src/images/arrow_right'
import RichText from 'src/components/rich_text'
import { RichText as BaseRichText } from 'prismic-reactjs'
import { classes } from 'src/utilities/classes'
import { Helmet } from "react-helmet"

const Steps = ({ data }) => {
  const tabNames = ['online','in_store'].filter(tabName => !data[`${tabName}_disabled`])
  const [stepIndex, setStepIndex] = useState(0)
  const [mobileStarted, setMobileStarted] = useState(false)
  const [tab, setTab] = useState(tabNames[0])
  const otherTab = tab === 'online' ? 'in_store' : 'online'
  const switchTab = () => {
    setStepIndex(0)
    setMobileStarted(false)
    setTab(otherTab)
  }

  return (
    tabNames.map(tabName => {
      const steps = data[`${tabName}_steps`]
      const currentStep = steps && steps[stepIndex]
      const heading = data[`${tabName}_heading`]
      const headingColor = data[`${tabName}_heading_color`]
      const heroImage = data[`${tabName}_hero_image`]
      const heroImageMobile = data[`${tabName}_hero_image_mobile`]
      const coverImageMobile = data[`${tabName}_cover_image_mobile`]
      const heroBackgroundColor = data[`${tabName}_hero_background_color`]
      const descriptionHeading = data[`${tabName}_description_heading`]
      const description = data[`${tabName}_description`]
      const signUpButtonText = data[`${tabName}_sign_up_button_text`]
      const signUpButtonLink = data[`${tabName}_sign_up_button_link`]

      const buttonIcon = data[`${tabName === 'online' ? 'in_store' : 'online'}_button_icon`]
      const buttonText = data[`${tabName === 'online' ? 'in_store' : 'online'}_button_text`]

      return (
        <div className={classes(styles.wrapper,(tabName === tab && styles.active))} key={tabName}>
          <Helmet style={[{cssText: `
            .${styles.heroImage}.${tabName} {
              background-image: url("${heroImage && heroImage.url}");
              background-color: ${heroBackgroundColor};
            }
            @media(max-width: 600px) {
              .${styles.heroImage}.${tabName} {
                background-image: url("${heroImageMobile && heroImageMobile.url}");
              }
            }
          `}]} />
          <div className={classes(styles.heroImage,tabName)}>
            <div className={styles.titleWrapper} style={{ color: headingColor || 'white' }}>
              <BaseRichText render={heading} />
              {tabNames.length > 1 && <button className={styles.switchTab} onClick={switchTab}>
                <Image>{buttonIcon}</Image>
                <div>{buttonText}</div>
                <ArrowRightNarrow />
              </button>}
            </div>
          </div>
          <div className={styles.stepInfo}>
            <div className={styles.left}>
              <BaseRichText render={descriptionHeading} />
              <BaseRichText render={description} />
              {/* Only visible at <=1024px */}
              {currentStep && <div className={styles.leftNav}>
                <div className={styles.nav}>
                  <h3>{currentStep.heading || `Step 0${stepIndex + 1}`}</h3>
                  <button onClick={() => setStepIndex(stepIndex - 1)} disabled={stepIndex <= 0}><ArrowLeft /></button>
                  <button onClick={() => setStepIndex(stepIndex + 1)} disabled={stepIndex >= (steps.length - 1)}><ArrowRight /></button>
                </div>
                <RichText>{currentStep.description}</RichText>
              </div>}
            </div>
            <div className={classes(styles.center, mobileStarted ? styles.mobileStarted : styles.mobileInitial)}>
              <div className={styles.imageWrapper} id={`steps-image-wrapper-${tabName}`}>
                {mobileStarted && stepIndex > 0 && <button onClick={() => setStepIndex(stepIndex - 1)} disabled={stepIndex <= 0}><ArrowLeft /></button>}
                {mobileStarted && stepIndex < 1 && <button onClick={() => setMobileStarted(false)}><ArrowLeft /></button>}
                <Image className={styles.mobileInitialImage}>
                  {coverImageMobile}
                </Image>
                {steps.map((step, i) =>
                  <Image className={classes(i === stepIndex ? styles.currentImage : styles.otherImage)} key={i}>
                    {step && step.feature_image}
                  </Image>
                )}
                {mobileStarted && <button onClick={() => setStepIndex(stepIndex + 1)} disabled={stepIndex >= (steps.length - 1)}><ArrowRight /></button>}
                {!mobileStarted && <button onClick={() => { setStepIndex(0); setMobileStarted(true); scrollToDetails(tabName); }} className={styles.startButton}>Start <ArrowRight /></button>}
              </div>
              <div className={styles.centerContent}>
                <div className={styles.mobileDescription}>
                  {mobileStarted ? <h3>{currentStep.heading || `Step 0${stepIndex + 1}`}</h3> : <BaseRichText render={descriptionHeading} />}
                  <BaseRichText render={mobileStarted ? currentStep.description : description} />
                </div>
                <LinkButton to={signUpButtonLink}>
                  {signUpButtonText}
                </LinkButton>
              </div>
            </div>
            <div className={styles.right}>
              {steps.map((step, i) =>
                <div style={{ display: (i === stepIndex ? 'block' : 'none') }} key={i}>
                  <div className={styles.nav}>
                    <h3>{step.heading || `Step 0${i + 1}`}</h3>
                    <button onClick={() => setStepIndex(stepIndex - 1)} disabled={stepIndex <= 0}><ArrowLeft /></button>
                    <button onClick={() => setStepIndex(stepIndex + 1)} disabled={stepIndex >= (steps.length - 1)}><ArrowRight /></button>
                  </div>
                  <RichText>{step.description}</RichText>
                </div>
              )}
            </div>
          </div>
        </div>
      )
    })
  )
}

const scrollToDetails = (tabName) => {
  const element = document.getElementById(`steps-image-wrapper-${tabName}`)
  if (element) {
    window.scrollTo(0, element.getBoundingClientRect().top + window.pageYOffset - 72)
  }
}

export default Steps